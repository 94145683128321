














































































import { Component, Mixins } from "vue-property-decorator";
import { NavBar, NoticeBar, Image, Swipe, SwipeItem, Dialog } from "vant";
import { Mixin } from "@/core/mixins/mixin";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [NoticeBar.name]: NoticeBar,
    [Image.name]: Image,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Dialog.Component.name]: Dialog.Component,
  },
})
export default class RedEnvelopeComp extends Mixins(Mixin) {
  showPrivacy = false;
  id = "";
  info: cms.ActivityInfoDto = { menuType: "" }; //详情信息
  num = 0; //抽奖次数
  integral = 0; //积分
  integralConsumption = 0; //消耗积分
  activeStatus = ""; // 活动状态
  level = false; // 是否满足会员等级
  isShowBack = true;
  updateData(): void {
    this.getAllMethods();
    document.addEventListener("visibilitychange", this.refreshPage);
  }
  beforeDestroy(): void {
    document.removeEventListener("visibilitychange", this.refreshPage);
  }
  //重置
  refreshPage(): void {
    if (document.visibilityState == "visible") {
      let ua = this.getBrowserEnvironment();
      if (ua.ios) {
        this.getAllMethods();
      }
    }
  }

  getAllMethods(): void {
    this.isShowBack = this.$route.query.isTab ? false : true; //是否是首页的tab,是不展示返回按钮
    this.id = this.$route.query.id ? String(this.$route.query.id) : "";
    if (this.id) {
      this.getNum();
      this.getInfo();
    }
  }

  onClickLeft(): void {
    this.$router.go(-1);
  }

  /*
   * ACTIVITY_STATUS_UPCOMING("0", "活动即将开始"),
   * ACTIVITY_STATUS_START_GAME("1", "开始游戏"),
   * ACTIVITY_STATUS_FINISHED("2", "活动已结束"),
   * ACTIVITY_STATUS_SOLD_OUT("3", "活动已下架");
   */
  getBtnType(activeStatus: string): string {
    let pic = "j1"; //默认开始
    if (activeStatus === "ACTIVITY_STATUS_UPCOMING") {
      pic = "j4";
    } else if (activeStatus === "ACTIVITY_STATUS_FINISHED") {
      pic = "j2";
    } else if (activeStatus === "ACTIVITY_STATUS_SOLD_OUT") {
      //未上架
      pic = "j3";
    }
    return pic;
  }

  /*
   * 获取次数
   */
  getNum(): void {
    this.$api.cmsApi.lucky.findActivityInfoAuxiliaryById(
      this.id,
      ({ data }) => {
        this.num = data.luckDrawTimes || 0;
        this.integral = data.integral || 0;
        this.integralConsumption = data.integralConsumption || 0;
        this.activeStatus = data.activeStatus || "";
        this.level = data.level || false;
      },
      (error) => {
        if (error.includes("@_@")) {
          this.$toast(error.split("@_@")[1]);
        } else {
          error && this.$toast(error);
        }
      }
    );
  }

  /*
   * 活动信息
   */
  getInfo(): void {
    this.$api.cmsApi.lucky.findActivityInfoById(
      this.id,
      ({ data }) => {
        this.info = data;
        window.document.title = data.activityName || "";
        if (
          this.ChannelModule.channel === "plat_ios" ||
          this.ChannelModule.channel === "plat_android"
        ) {
          if (data.activeShare === 1) {
            this.jsBridge("isShowShare", "1");
          } else {
            this.jsBridge("isShowShare", "0");
          }
          window.startShare = this.shareDate;
        }
      },
      (error) => {
        if (error.includes("@_@")) {
          this.$toast(error.split("@_@")[1]);
        } else {
          error && this.$toast(error);
        }
      }
    );
  }

  //分享到原生app
  shareDate(): void {
    let url = `${location.origin}${location.pathname}#/red-envelope?id=${this.id}`;
    let title = this.info.activityName || "";
    let shareDescribe = this.info.shareDescription || "";
    let shareImg = this.info.shareImageUrl || "";
    this.jsBridge(
      "share",
      `title%&@/${title}%&@,desc%&@/${shareDescribe}%&@,picUrl%&@/${shareImg}%&@,url%&@/${url}`
    );
  }
  /*
   * 开始下红包雨
   */
  onClick(): void {
    if (this.activeStatus !== "ACTIVITY_STATUS_START_GAME") {
      return;
    }
    if (this.num === 0) {
      this.$toast("你的游戏次数已经用完了，明天再来试试吧");
      return;
    } else if (
      this.integralConsumption > 0 &&
      this.integral < this.integralConsumption
    ) {
      this.$toast("您的可用积分不足");
      return;
    } else if (!this.level) {
      this.$toast("会员等级不满足");
      return;
    }
    this.$router.push(`/red-envelope-rain?id=${this.id}`);
  }
}
