






















import { Component, Mixins, Prop } from "vue-property-decorator";
import { Row, Image, Icon } from "vant";
import { Mixin } from "@/core/mixins/mixin";

@Component({
  components: {
    [Row.name]: Row,
    [Image.name]: Image,
    [Icon.name]: Icon,
  },
})
export default class PrizeLevel extends Mixins(Mixin) {
  @Prop({
    type: Number,
    default: () => {
      return 2;
    },
  })
  public kind!: number;
  @Prop({
    type: Number,
    default: () => {
      return 2;
    },
  })
  public type!: number; // 1 可以再次抽奖 ，2 没有再次抽奖

  @Prop({
    type: Object,
    default: () => {
      return () => {
        return {};
      };
    },
  })
  public activityPrizeDto!: cms.ActivityPrizeDto;

  isShow = false;
  /*
   * 再来一次
   */
  goAgain(): void {
    this.$emit("goAgain");
  }
  /*
   * 去兑换
   */
  goExchange(): void {
    this.$emit("goExchange");
  }
}
