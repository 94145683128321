











































































import { Component, Mixins, Prop, Vue } from "vue-property-decorator";
import { Row, Col, Tab, Tabs, Button, Stepper, Image, NavBar } from "vant";
import { Mixin } from "@/core/mixins/mixin";
@Component({
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Button.name]: Button,
    [Stepper.name]: Stepper,
    [Image.name]: Image,
    [NavBar.name]: NavBar,
  },
})
export default class GoodDetail extends Mixins(Mixin) {
  public goodDetailInfos = {};
  public value = 0;
  public active = "0";
  public commodityId = -1;
  public price: Array<good.SpecsMemberPriceDto> = [];
  created(): void {
    this.init();
  }
  init(): void {
    this.commodityId = Number(this.$route.query.commodityId);
    this.getInfo();
  }
  onClickLeft(): void {
    this.$router.go(-1);
  }
  goExchange(): void {
    this.$router.push(`/mall/confirm-order`);
  }
  getInfo(): void {
    this.$api.goodApi.commodity.getCommodityDetail(
      Number(this.commodityId),
      ({ data }) => {
        this.goodDetailInfos = data;
        this.price = data.lowestMemberPrices!;
      }
    );
  }
}
