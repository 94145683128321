






import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import RedEnvelopeDemo from "@/views/LimitActivities/ActivityDemo/RedEnvelopeDemo.vue";
@Component({
  components: {
    RedEnvelopeDemo,
  },
})
export default class RedEnvelope extends Mixins(Mixin) {
  mounted(): void {
    let RedEnvelope = this.$refs["red-envelopr-comp"] as RedEnvelopeDemo;
    if (RedEnvelope) {
      RedEnvelope.updateData();
    }
  }
}
